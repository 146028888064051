import { Grid } from '@mui/material';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { UserContext } from '../../../../shared/contexts/UserContext';
import {
  MultipleChoiceQuestion,
  QuestionType,
  TextQuestion,
  RatingQuestion,
} from '../../../../shared/domain/form';
import {
  BasicReview,
  RatingAspect,
  ReviewDepth,
  CurrentReview,
  ReviewStep,
} from '../../../../shared/domain/review';
import { H5, P } from '../../../../shared/styles';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import {
  RatingValues,
  createCompanyFromReview,
  createBasicReview,
  RatingsInput,
  createBasicReviewRatings,
} from '../../api/api';
import { useReviewContext } from '../../context/ReviewContext';
import { BASIC_REVIEW_FORM_FIELDS } from '../../data';
import useValidation from '../../hooks/useValidation';
import { Container } from '../../styles/review.styles';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';
import ReviewFormButtons from '../ReviewFormButtons';
import ReviewRatingQuestion from '../question_types/ReviewRatingQuestion';
import AddCompanyForm from '../AddCompanyForm';

type BasicReviewTypeProps = {
  companyId: number;
  companyName: string;
  cancelReview: () => void;
  handleReviewSuccess: () => void;
  setRfp: (rfp: boolean) => void;
  isAddCompany?: boolean;
};

const StyledHeader = styled(H5)`
  margin: auto;
  text-align: center;
  margin-bottom: var(--spacing-lg);
`;

const RatingsWrapper = styled.div`
  padding: var(--spacing-md);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledText = styled(P)`
  margin: 0 var(--spacing-md);
  margin-top: var(--spacing-md);
`;

const BasicReviewForm: React.FC<BasicReviewTypeProps> = ({
  companyId,
  companyName,
  setRfp,
  isAddCompany,
  cancelReview,
  handleReviewSuccess,
}) => {
  const {
    fetchAllReviews,
    currentReview,
    setReviewId,
    isViewMode,
    setReviewStep,
  } = useReviewContext();
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const ratingValues = useMemo(
    () => ({
      transparency:
        currentReview?.ratings?.find(
          (r) => r.type === RatingAspect.TRANSPARENCY
        )?.rating || 0,
      clarity:
        currentReview?.ratings?.find((r) => r.type === RatingAspect.CLARITY)
          ?.rating || 0,
      responsiveness:
        currentReview?.ratings?.find(
          (r) => r.type === RatingAspect.RESPONSIVENESS
        )?.rating || 0,
      understanding:
        currentReview?.ratings?.find(
          (r) => r.type === RatingAspect.UNDERSTANDING
        )?.rating || 0,
    }),
    [currentReview]
  );

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<BasicReview>(currentReview ?? ({} as BasicReview));

  const [ratingFormFields, setRatingFormFields] =
    useState<RatingValues>(ratingValues);

  const [newCompany, setNewCompany] = useState({
    companyName: '',
    stockTicker: '',
  });

  const { errors, validateFields, validateField } = useValidation(
    { ...formFields, ...ratingFormFields },
    BASIC_REVIEW_FORM_FIELDS
  );

  const buttonText = useMemo(() => {
    if (currentReview && !isViewMode) return 'Purchase Details';
    if (isViewMode && currentReview?.reviewDepth === ReviewDepth.BASIC)
      return 'Done';
    return isViewMode ? 'View Purchase Details' : 'Make it a Deep Dive';
  }, [currentReview, isViewMode]);

  const handleViewMode = (
    currentReview: CurrentReview,
    isViewMode: boolean,
    companyName: string
  ) => {
    if (currentReview?.reviewDepth === ReviewDepth.BASIC && isViewMode) {
      navigate(`/company-search/${encodeURIComponent(companyName)}`, {
        replace: true,
      });
      handleReviewSuccess();
      return true;
    }
    if (isViewMode) {
      setReviewStep(ReviewStep.PURCHASE_DETAILS);
      return true;
    }

    return false;
  };

  const postReviewSteps = (
    isReviewDeepDive: boolean,
    companyId: number,
    handleReviewSuccess: () => void,
    fetchAllReviews: (companyId: number) => void
  ) => {
    if (isReviewDeepDive) {
      setReviewStep(ReviewStep.PURCHASE_DETAILS);
    } else {
      fetchAllReviews(companyId);
      handleReviewSuccess();
    }
  };

  const handleRatingChange = (name: string, value: number | null) => {
    setRatingFormFields({
      ...ratingFormFields,
      [name]: value || 0,
    });
    validateField(name, value); // Validate field dynamically
  };

  const submitBasicReviewType = async (
    e: React.FormEvent<HTMLFormElement>,
    isReviewDeepDive?: boolean
  ) => {
    e.preventDefault();
    if (isViewMode) {
      handleViewMode(currentReview, isViewMode, companyName);
      return;
    }
    const hasErrors = validateFields();
    if (!user?.idToken || hasErrors) {
      return;
    }

    try {
      const createdCompany = isAddCompany
        ? await createCompanyFromReview(newCompany, user?.idToken)
        : null;

      const reviewInput = {
        ...formFields,
        reviewType: 'OWNED',
        reviewDepth:
          currentReview?.reviewDepth || isReviewDeepDive
            ? ReviewDepth.DEEP_DIVE
            : ReviewDepth.BASIC,
        userId: user?.id,
        companyId: createdCompany?.id || companyId,
        companyName: createdCompany?.companyName || companyName,
        key: ReviewStep.BASIC_REVIEW,
      };

      const createdBasicReviewId = await createBasicReview(
        reviewInput,
        user?.idToken
      );

      setReviewId(createdBasicReviewId);

      const ratingsInput: RatingsInput = {
        companyId: createdCompany?.id || companyId,
        reviewId: createdBasicReviewId,
        ratings: {
          transparency: ratingFormFields.transparency,
          clarity: ratingFormFields.clarity,
          responsiveness: ratingFormFields.responsiveness,
          understanding: ratingFormFields.understanding,
        },
      };

      await createBasicReviewRatings(ratingsInput, user?.idToken);

      postReviewSteps(
        isReviewDeepDive || false,
        companyId,
        handleReviewSuccess,
        fetchAllReviews
      );
    } catch (error) {
      cancelReview();
    }
  };
  useEffect(() => {
    setRfp(formFields.dealRfp === 'Yes');
  }, [formFields.dealRfp, setRfp]);

  useEffect(() => {
    setFormFields(currentReview ?? ({} as BasicReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        {!isViewMode && (
          <StyledHeader>
            Thank you for sharing with Kandir! Let's start by collecting the
            basic review information.
          </StyledHeader>
        )}
        {isAddCompany && (
          <AddCompanyForm
            newCompany={newCompany}
            setNewCompany={setNewCompany}
            errors={errors}
          />
        )}
        {BASIC_REVIEW_FORM_FIELDS.filter(
          (q): q is MultipleChoiceQuestion =>
            q.type === QuestionType.MULTIPLE_CHOICE
        ).map((question) => (
          <MultipleChoiceReviewQuestion
            key={question.name}
            question={question}
            formFields={formFields}
            handleFormFieldChange={(e) => {
              extendFormFields({ [question.name]: e.currentTarget.value });
              validateField(question.name, e.currentTarget.value);
            }}
            errors={errors}
            isNarrow={question.options.length > 8}
          />
        ))}

        <Grid container spacing={2}>
          {BASIC_REVIEW_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          ).map((question, i) => (
            <ReviewTextArea
              key={question.name}
              question={question}
              formFields={formFields}
              handleFormFieldChange={(e) => {
                extendFormFields({ [question.name]: e.currentTarget.value });
                validateField(question.name, e.currentTarget.value);
              }}
              errors={errors}
              includeTextLimitValidation
              index={i}
            />
          ))}

          <StyledText>
            On a scale of 1 - 5, how would you rate the companies:
          </StyledText>
          <RatingsWrapper>
            {BASIC_REVIEW_FORM_FIELDS.filter(
              (r): r is RatingQuestion => r.type === QuestionType.RATING
            ).map((question, i) => (
              <ReviewRatingQuestion
                handleRatingChange={handleRatingChange}
                key={question.name}
                question={question}
                formFields={ratingFormFields}
                errors={errors}
              />
            ))}
          </RatingsWrapper>
        </Grid>
        <ReviewFormButtons
          reviewStep={ReviewStep.BASIC_REVIEW}
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitBasicReviewType(e, true)
          }
          submitButtonText="Publish Basic Review"
          nextButtonText={buttonText}
          onSubmit={submitBasicReviewType}
        />
      </form>
    </Container>
  );
};

export default BasicReviewForm;
