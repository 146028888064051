import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DraggableList, {
  Item,
} from '../../../../shared/components/DraggableList';
import { StyledTextField } from '../../styles/review.styles';
import { StyledQuestionText } from './ReviewMultipleChoiceQuestion';
import _ from 'lodash';
import { DraggableListQuestion } from '../../../../shared/domain/form';
import { useReviewContext } from '../../context/ReviewContext';
import styled from 'styled-components';

type ReviewDraggableListProps = {
  question: DraggableListQuestion;
  formFields: Record<string, any>;
  handleFormFieldChange: React.Dispatch<
    React.SetStateAction<Record<string, any>>
  >;
};

const InlineContainer = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-md);
  margin-bottom: 28px;
`;

const StyledButton = styled(Button)`
  flex-shrink: 0;
`;

const ReviewDraggableListQuestion = (props: ReviewDraggableListProps) => {
  const { formFields, handleFormFieldChange, question } = props;
  const [items, setItems] = useState<Item[]>([]);
  const [currentItem, setCurrentItem] = useState<string>('');

  const { isViewMode } = useReviewContext();

  useEffect(() => {
    const initialItems =
      (formFields[question.name] || []).map((text: string, index: number) => ({
        id: String(index + 1),
        text,
      })) || [];
    setItems(initialItems);
  }, [formFields, question.name]);

  const updateFormFields = (updatedItems: Item[]) => {
    const updatedList = updatedItems.map((item) => item.text);
    if (!_.isEqual(formFields[question.name], updatedList)) {
      handleFormFieldChange((prev) => ({
        ...prev,
        [question.name]: updatedList,
      }));
    }
  };

  const handleAddItem = () => {
    const updatedItems = [
      ...items,
      { id: String(items.length + 1), text: currentItem },
    ];
    setItems(updatedItems);
    updateFormFields(updatedItems);
    setCurrentItem('');
  };

  return (
    <Grid item sm={6} xs={12} marginTop={4}>
      <StyledQuestionText style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}>
        {question.question}
      </StyledQuestionText>
      <InlineContainer>
        <StyledTextField
          isWide
          value={currentItem}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setCurrentItem(e.target.value)
          }
          required
          placeholder={question.placeholder}
          size="small"
          InputProps={{
            readOnly: isViewMode,
          }}
        />
        <StyledButton
          variant="outlined"
          disabled={!currentItem}
          onClick={handleAddItem}
        >
          Add
        </StyledButton>
      </InlineContainer>
      <DraggableList items={items} />
    </Grid>
  );
};

export default ReviewDraggableListQuestion;
