import { useState, useRef } from 'react';
import _ from 'lodash';
import { Question, QuestionValidationRules } from '../../../shared/domain/form';
import { CurrentReview } from '../../../shared/domain/review';
import { shouldShowQuestion } from '../helpers/ReviewFormHelpers';

type ValidationErrors = Record<string, string>;

const MINIMUM_CHARACTERS = 50;

const useValidation = (
  formFields: Record<string, any>,
  questions: Question[]
) => {
  const [errors, setErrors] = useState<ValidationErrors>({});

  const validateField = (fieldName: string, value: any) => {
    const question = questions.find((q) => q.name === fieldName);
    if (
      !question ||
      !shouldShowQuestion(question, formFields as CurrentReview)
    ) {
      return;
    }

    const fieldErrors: ValidationErrors = { ...errors };

    if (
      question.validationRules?.includes(QuestionValidationRules.REQUIRED) &&
      (value === undefined ||
        value === null ||
        (typeof value === 'string' && value.trim() === '') ||
        (typeof value === 'number' && value <= 0) ||
        (Array.isArray(value) && value.length === 0))
    ) {
      fieldErrors[fieldName] = 'This field is required';
    } else {
      delete fieldErrors[fieldName];
    }

    setErrors(fieldErrors);
  };

  const validateFields = () => {
    const validationErrors: ValidationErrors = {};

    questions.forEach((question) => {
      if (!shouldShowQuestion(question, formFields as CurrentReview)) {
        return;
      }

      const value = formFields[question.name];

      if (
        question.validationRules?.includes(QuestionValidationRules.REQUIRED) &&
        (value === undefined ||
          value === null ||
          (typeof value === 'string' && value.trim() === '') ||
          (typeof value === 'number' && value <= 0) ||
          (Array.isArray(value) && value.length === 0))
      ) {
        validationErrors[question.name] = 'This field is required';
      }
      if (
        question.validationRules?.includes(
          QuestionValidationRules.MINIMUM_CHARACTERS
        ) &&
        typeof value === 'string' &&
        value.trim().length < MINIMUM_CHARACTERS
      ) {
        validationErrors[question.name] =
          `This field must have at least ${MINIMUM_CHARACTERS} characters.`;
      }
    });

    setErrors(validationErrors);

    if (!_.isEmpty(validationErrors)) {
      const firstErrorKey = Object.keys(validationErrors)[0];
      const errorElement = document.querySelector(
        `[data-error-name="${firstErrorKey}"]`
      );

      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });

        if (
          errorElement instanceof HTMLElement &&
          typeof errorElement.focus === 'function'
        ) {
          errorElement.focus();
        }
      }
    }

    return !_.isEmpty(validationErrors);
  };

  return { errors, validateFields, validateField };
};

export default useValidation;
