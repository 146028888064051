import React from 'react';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { styled } from '@mui/material';

type CustomTooltipProps = {
  text: string;
  placement?: 'top' | 'bottom' | 'left' | 'right';
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    padding: 12,
  },
}));

const CustomTooltip = (props: CustomTooltipProps) => {
  const { text, placement } = props;
  if (!text) return null;

  return (
    <LightTooltip title={text} placement={placement || 'top'} arrow>
      <IconButton aria-label="question-tooltip">
        <HelpOutlineIcon />
      </IconButton>
    </LightTooltip>
  );
};

export default CustomTooltip;
