import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import { UserContext } from '../../../shared/contexts/UserContext';
import { ReviewStep } from '../../../shared/domain/review';
import NegotiationReviewForm from './review_forms/NegotiationReviewForm';
import PurchaseDetailsReviewForm from './review_forms/PurchaseDetailsReviewForm';
import ReviewDialogHeader from './ReviewDialogHeader';
import ReviewStepper from './ReviewStepper';
import RfpReviewForm from './review_forms/RfpReviewForm';
import ModalTransition from '../../../shared/ModalTransition';
import { useReviewContext } from '../context/ReviewContext';
import Loader from '../../../shared/components/Loader';
import { getLoaderText } from '../helpers/ReviewFormHelpers';
import BasicReviewForm from './review_forms/BasicReviewForm';
import EvaluationReviewForm from './review_forms/EvaluationReviewForm';
import LegalReviewForm from './review_forms/LegalReviewForm';

type ReviewDialogProps = {
  open: boolean;
  companyName?: string;
  companyId?: number;
  setOpen: (isOpen: boolean) => void;
  isAddCompany?: boolean;
};

const ReviewDialog = (props: ReviewDialogProps) => {
  const { open, setOpen, companyName, companyId, isAddCompany } = props;
  const [rfp, setRfp] = useState(false);
  const [showContent, setShowContent] = useState(false);

  const { user } = useContext(UserContext);
  const {
    handleRatingSuccess,
    handleServerError,
    fetchAllReviews,
    isViewMode,
    reviewId,
    reviewStep,
    setReviewStep,
    loading,
    setLoading,
    setLastStepSaved,
    lastStepSaved,
  } = useReviewContext();

  const handleClose = () => {
    handleRatingSuccess();
    setOpen(false);
  };

  const handleBack = (step: ReviewStep) => {
    setReviewStep(step);
  };

  const handleViewMode = (key: ReviewStep) => {
    setLoading(true);
    if (isViewMode && shouldCompleteReview(key)) {
      handleRatingSuccess();
      setOpen(false);
      return;
    }
    setReviewStep(reviewStep + 1);
  };

  const shouldCompleteReview = (key: ReviewStep) =>
    key === ReviewStep.RFP || (key === ReviewStep.NEGOTIATION && !rfp);

  const submitReviewForm = async (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    key: ReviewStep,
    isReviewDeepDive?: boolean
  ) => {
    setLoading(true);
    if (!user?.idToken) return;
    e.preventDefault();

    if (isViewMode) {
      handleViewMode(key);
      return;
    }

    const reviewInput = {
      ...formFields,
      key,
      reviewId,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review`,
        reviewInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setLastStepSaved(Math.max(key, lastStepSaved));

      if (isReviewDeepDive && shouldCompleteReview(key)) {
        await completeReview();
      } else {
        setReviewStep(reviewStep + 1);
        setLoading(false);
      }
    } catch (error) {
      handleServerError();
      setOpen(false);
    }
  };

  const completeReview = () => {
    if (!companyId) return;
    fetchAllReviews(companyId);
    handleRatingSuccess(true);
    setOpen(false);
  };

  useEffect(() => {
    if (loading) {
      setShowContent(false);
    } else {
      const timer = setTimeout(() => setShowContent(true), 300);
      return () => clearTimeout(timer);
    }
  }, [loading]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={ModalTransition}
    >
      <ReviewDialogHeader
        handleClose={handleClose}
        companyName={companyName || 'a New Company'}
        isDeepDive={!!reviewStep}
      />

      {loading ? (
        <Loader loaderText={getLoaderText(reviewStep)} />
      ) : (
        showContent && (
          <>
            {reviewStep !== ReviewStep.BASIC_REVIEW && (
              <ReviewStepper handleBack={setReviewStep} rfp={rfp} />
            )}
            {reviewStep === ReviewStep.BASIC_REVIEW && (
              <BasicReviewForm
                companyId={companyId || 0}
                companyName={companyName || 'New Company'}
                cancelReview={() => {
                  handleServerError();
                  setOpen(false);
                }}
                handleReviewSuccess={() => {
                  handleRatingSuccess(true);
                  setOpen(false);
                }}
                setRfp={setRfp}
                isAddCompany={isAddCompany}
              />
            )}
            {reviewStep === ReviewStep.PURCHASE_DETAILS && (
              <PurchaseDetailsReviewForm
                submitReviewForm={submitReviewForm}
                handleViewMode={handleViewMode}
              />
            )}
            {reviewStep === ReviewStep.EVALUATION && (
              <EvaluationReviewForm
                submitReviewForm={submitReviewForm}
                handleViewMode={handleViewMode}
              />
            )}
            {reviewStep === ReviewStep.LEGAL && (
              <LegalReviewForm
                submitReviewForm={submitReviewForm}
                handleViewMode={handleViewMode}
              />
            )}
            {reviewStep === ReviewStep.NEGOTIATION && (
              <NegotiationReviewForm
                submitReviewForm={submitReviewForm}
                handleViewMode={handleViewMode}
                rfp={rfp}
                setOpen={setOpen}
              />
            )}
            {reviewStep === ReviewStep.RFP && (
              <RfpReviewForm
                submitReviewForm={submitReviewForm}
                handleViewMode={handleViewMode}
              />
            )}
          </>
        )
      )}
    </Dialog>
  );
};

export default ReviewDialog;
