import React from 'react';
import styled from 'styled-components';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import { StyledTextField } from '../styles/review.styles';
import { StyledQuestionText } from './question_types/ReviewMultipleChoiceQuestion';

const QuestionWrapper = styled.div`
  margin-bottom: var(--spacing-md);
`;

type AddCompanyFormProps = {
  newCompany: { companyName: string; stockTicker: string };
  setNewCompany: React.Dispatch<
    React.SetStateAction<{ companyName: string; stockTicker: string }>
  >;
  errors: Record<string, string>;
};

const AddCompanyForm: React.FC<AddCompanyFormProps> = ({
  newCompany,
  setNewCompany,
  errors,
}) => {
  return (
    <>
      <QuestionWrapper>
        <StyledQuestionText>
          What is the name of the company you are reviewing?
        </StyledQuestionText>
        <div style={{ width: '80%' }}>
          <StyledTextField
            onChange={(e) =>
              setNewCompany({
                ...newCompany,
                companyName: e.target.value,
              })
            }
            size="small"
            required
          />
          {errors.economicBuyerName && (
            <ErrorMessage error={errors.economicBuyerName} />
          )}
        </div>
      </QuestionWrapper>
      <QuestionWrapper>
        <StyledQuestionText>
          What is the NASDAQ Stock Ticker of the company you are reviewing?
        </StyledQuestionText>
        <div style={{ width: '80%' }}>
          <StyledTextField
            required
            onChange={(e) =>
              setNewCompany({
                ...newCompany,
                stockTicker: e.target.value,
              })
            }
            size="small"
          />
          {errors.economicBuyerName && (
            <ErrorMessage error={errors.economicBuyerName} />
          )}
        </div>
      </QuestionWrapper>
    </>
  );
};

export default AddCompanyForm;
