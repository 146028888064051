import React, { useEffect } from 'react';
import { Grid } from '@mui/material';
import {
  NegotiationReview,
  ReviewStep,
} from '../../../../shared/domain/review';
import { Container } from '../../styles/review.styles';
import ReviewFormButtons from '../ReviewFormButtons';
import { NEGOTIATION_FORM_FIELDS } from '../../data';
import {
  MultipleChoiceQuestion,
  QuestionType,
  ShortTextQuestion,
  TextQuestion,
} from '../../../../shared/domain/form';
import { useReviewContext } from '../../context/ReviewContext';
import useValidation from '../../hooks/useValidation';
import { shouldShowQuestion } from '../../helpers/ReviewFormHelpers';
import { useExtendState } from '../../../../shared/utils/ExtendState';
import MultipleChoiceReviewQuestion from '../question_types/ReviewMultipleChoiceQuestion';
import ReviewTextArea from '../question_types/ReviewTextQuestion';
import ReviewShortTextQuestion from '../question_types/ReviewShortTextQuestion';

type NegotiationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: NegotiationReview,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  rfp: boolean;
  setOpen?: (isOpen: boolean) => void;
  handleViewMode: (key: ReviewStep) => void;
};

const NegotiationReviewForm = (props: NegotiationReviewProps) => {
  const { submitReviewForm, rfp, setOpen, handleViewMode } = props;

  const { currentReview, isViewMode } = useReviewContext();

  const [formFields, extendFormFields, setFormFields] =
    useExtendState<NegotiationReview>(
      currentReview ?? ({} as NegotiationReview)
    );

  const { errors, validateFields, validateField } = useValidation(
    formFields,
    NEGOTIATION_FORM_FIELDS
  );

  const buttonText = () => {
    if ((rfp && !isViewMode) || (rfp && isViewMode)) {
      return 'Move onto RFP';
    }
    if (!rfp && isViewMode) {
      return 'Done';
    } else {
      return 'Submit Deep Dive Review';
    }
  };

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    extendFormFields({
      ...formFields,
      [name]: value,
    });
    validateField(name, value);
  };

  const submitNegotiationReview = async (
    e: React.FormEvent<HTMLFormElement>
  ) => {
    e.preventDefault();
    const hasErrors = validateFields();
    if (hasErrors) {
      return;
    }

    submitReviewForm(e, formFields, ReviewStep.NEGOTIATION, true);
  };

  useEffect(() => {
    setFormFields(currentReview ?? ({} as NegotiationReview));
  }, [currentReview]);

  return (
    <Container>
      <form>
        {NEGOTIATION_FORM_FIELDS.filter((question) =>
          shouldShowQuestion(question, formFields)
        ).map((question, index) => {
          if (question.type === QuestionType.MULTIPLE_CHOICE) {
            return (
              <MultipleChoiceReviewQuestion
                key={question.name}
                question={question as MultipleChoiceQuestion}
                data-error-name={question.name}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                isNarrow={
                  (question as MultipleChoiceQuestion).options.length > 8
                }
              />
            );
          }

          if (question.type === QuestionType.SHORT_TEXT) {
            return (
              <ReviewShortTextQuestion
                key={question.name}
                question={question as ShortTextQuestion}
                data-error-name={question.name}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
              />
            );
          }

          return null;
        })}
        <Grid container spacing={2}>
          {NEGOTIATION_FORM_FIELDS.filter(
            (q): q is TextQuestion => q.type === QuestionType.TEXT
          )
            .filter((question) => shouldShowQuestion(question, formFields))
            .map((question, i) => (
              <ReviewTextArea
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                errors={errors}
                index={i}
              />
            ))}
        </Grid>
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? handleViewMode(ReviewStep.NEGOTIATION)
              : submitNegotiationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText={buttonText()}
          onSubmit={(e: React.FormEvent<HTMLFormElement>) =>
            isViewMode
              ? setOpen && setOpen(false)
              : submitReviewForm(e, formFields, ReviewStep.NEGOTIATION)
          }
        />
      </form>
    </Container>
  );
};

export default NegotiationReviewForm;
